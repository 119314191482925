// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-works-js": () => import("./../src/pages/all-works.js" /* webpackChunkName: "component---src-pages-all-works-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-work-js": () => import("./../src/templates/Work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

